<template>
  <div id="navbar">
    <toolbar></toolbar>
    <tagsview></tagsview>
  </div>
</template>

<script>
import Toolbar from "@/layouts/Navbar/components/Toolbar";
import Tagsview from "@/layouts/Navbar/components/Tagsview";
export default {
  name: "index",
  components: { Tagsview, Toolbar },
};
</script>

<style scoped></style>
