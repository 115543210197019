<template>
  <div id="toolbar">
    <a-button type="link" @click="toggleCollapsed">
      <template #icon>
        <MenuUnfoldOutlined
          style="color: white; font-size: 1.25em"
          v-if="collapsed" />
        <MenuFoldOutlined v-else style="color: white; font-size: 1.25em"
      /></template>
    </a-button>
    <div
      style="
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
      "
    >
      <a-dropdown>
        <div style="display: flex; align-items: center">
          <a-avatar style="background-color: #87d068">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
          <div>
            <i>{{ $store.state.user.name }}</i>
          </div>
        </div>
        <template #overlay>
          <a-menu mode="horizontal" @click="clickMenu">
            <a-menu-item key="userCenter">个人中心</a-menu-item>
            <a-menu-item key="logout">注销</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons-vue";
import { computed } from "vue";
import { useStore } from "vuex";
import useMessage from "@/hooks/useMessage";
export default {
  name: "Toolbar",
  setup() {
    const store = useStore();
    const { showTipMessage } = useMessage();
    const collapsed = computed(() => {
      return store.state.sidebar.isCollapsed;
    });
    const toggleCollapsed = () => {
      store.dispatch("sidebar/toggleSidebar");
    };
    const clickMenu = async ({ key }) => {
      if (key === "logout") {
        await store.dispatch("user/logout");
        await showTipMessage("注销成功");
        location.reload();
      }
    };
    return {
      collapsed,
      toggleCollapsed,
      clickMenu,
    };
  },
  components: {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
  },
};
</script>

<style scoped></style>
