<template>
  <div id="tagsview">{{ title }}</div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed } from "vue";

export default {
  name: "Tagsview",
  setup() {
    const route = useRoute();
    const title = computed(() => {
      return route.meta ? route.meta.title || "" : "";
    });
    return {
      title,
    };
  },
};
</script>

<style scoped></style>
